/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Dropzone, FullScreenPreview, FileItem } from '@dropzone-ui/react';
import { spacing } from '../../themes/appTheme';
import './AvDropzone.css';

const StyledDropzoneLabel = styled('div')(
  ({ theme }) => `
  width: 100%;
  padding: 0 ${theme.spacing(2)};
  h6 {
    text-align: left;
  }
  ol {
    width: 100%;
    padding: 0 ${theme.spacing(2)};
  }
  li {
    list-style-position: inside;
    text-align: left;
    font-family: ${theme.typography.body2.fontFamily};
    font-size: ${theme.typography.body2.fontSize};
  }
`,
);

function AvDropzoneLabel({ className = 'av-dropzone-label', children }) {
  return (
    <StyledDropzoneLabel className={className}>
      {children}
    </StyledDropzoneLabel>
  );
}

function AvFileItems({ fileState, fileStateHandler, onSee }) {
  const deleteFileHandler = (fileHandler, id) => {
    fileHandler((files) => files.filter((x) => x.id !== id));
  };

  return fileState.map((file) => (
    <FileItem
      {...file}
      key={file.id}
      onDelete={(id) => deleteFileHandler(fileStateHandler, id)}
      onSee={onSee}
      resultOnTooltip
      preview
      info
      hd
    />
  ));
}

// eslint-disable-next-line import/prefer-default-export
export default function AvDropzone({ onFilesChange, value, label, maxFiles, accept, maxFileSize }) {
  const [imageSrc, setImageSrc] = React.useState(null);

  const handleFileListChange = (e) => {
    if (Array.isArray(e) && maxFiles) {
      onFilesChange(e.slice(0, maxFiles));
    } else {
      onFilesChange(e);
    }
  };
  return (
    <Dropzone
      header={false}
      style={{ width: '100%', marginTop: spacing(2) }}
      onChange={handleFileListChange}
      value={value}
      maxFiles={maxFiles}
      maxFileSize={maxFileSize}
      accept={accept}
      label={(
        <AvDropzoneLabel>
          {label}
        </AvDropzoneLabel>
      )}
    >
      <AvFileItems
        fileState={value}
        fileStateHandler={handleFileListChange}
        onSee={setImageSrc}
      />
      <FullScreenPreview
        imgSource={imageSrc}
        openImage={imageSrc}
        onClose={() => setImageSrc(undefined)}
      />
    </Dropzone>
  );
}

AvDropzoneLabel.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

AvDropzoneLabel.defaultProps = {
  className: 'av-dropzone-label',
};
