import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import jwt from 'jsonwebtoken';

import appTheme from './shared/themes/appTheme';
import Router from './Router';
import oktaConfig from './oktaConfig';
import UserCache from './models/User';
import AuthStatusCache from './models/AuthStatus';
import TokenCache from './models/Token';
import { loginAVAdminUser } from './shared/services/apiGateway';

const oktaAuth = new OktaAuth(oktaConfig.oidc);

function App() {
  const userCache = new UserCache();
  const authStatusCache = new AuthStatusCache();
  const tokenCache = new TokenCache();
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(authStatusCache.get() || false);
  const [userInfo, setUserInfo] = useState(userCache.get() || {});
  const [userInfoLoading, setUserInfoLoading] = useState(false);

  const restoreOriginalUri = React.useCallback((_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }, []);

  const loginUserWithAccessToken = async (accessToken) => {
    if (accessToken) {
      const payload = {
        authProviderData: { oktaAccessToken: accessToken },
        provider: 'Okta',
      };

      // decrypt the access token from res
      const res = await loginAVAdminUser(payload);
      tokenCache.set(res);
      const serverAccessToken = res.access_token;
      const decodedUserInfo = jwt.decode(serverAccessToken).userInfo;

      if (!decodedUserInfo || (decodedUserInfo.roles && decodedUserInfo.roles.length === 0)) {
        history.push('/page-not-found');
        window.localStorage.clear();
        return;
      }

      const { endpoints, ...publicUserInfo } = decodedUserInfo;

      setUserInfo(publicUserInfo);

      userCache.setUser(publicUserInfo);
      setUserInfoLoading(false);
    }
  };

  const updateAuthentication = () => {
    oktaAuth.authStateManager.subscribe((authState) => {
      const authenticated = authState.isAuthenticated;
      if (authenticated) {
        oktaAuth.authStateManager.unsubscribe();
        setIsAuthenticated(authenticated);
        authStatusCache.set(authenticated);
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      const accessToken = oktaAuth.getAccessToken();
      if ((!userInfo || !userInfo.email) && !userInfoLoading && accessToken) {
        setUserInfoLoading(true);
        loginUserWithAccessToken(accessToken);
      }
    } else {
      updateAuthentication();
    }
  }, [isAuthenticated]);

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <ThemeProvider theme={appTheme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Router />
        </LocalizationProvider>
      </ThemeProvider>
    </Security>
  );
}

export default App;
