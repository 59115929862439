import { useState, useEffect } from 'react';

function useFilteredBySearch(arr, query) {
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (Array.isArray(arr)) {
      const data = [...arr];
      if (!query) {
        setFilteredData(data);
      } else {
        const lowQuery = `${query}`.toLowerCase();
        const filtered = data.filter((el) => `${Object.values(el).join(" ")}`.toLowerCase().includes(lowQuery));
        setFilteredData(filtered);
      }
    }
  }, [arr, query]);

  return filteredData;
}

export default useFilteredBySearch;
