import { useState, useEffect } from 'react';
import { getErrorReports } from '../services/apiGateway';

function useErrorReports(setLoading) {
  const [errorReports, setErrorReports] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchData() {
      const response = await getErrorReports(abortController.signal);
      setErrorReports(response ? response.data : []);
      if (setLoading) {
        setLoading(false);
      }
    }

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return errorReports;
}

export default useErrorReports;
