import sortAlphanumerically from '../shared/utils/sortAlphanumerically';
import sortByDate from '../shared/utils/sortAsDate';

export default function createErrorReportsTableDefintion() {
  return [
    {
      field: 'userId',
      headerName: 'Investor ID',
      description: 'The investor\'s ID',
      editable: false,
      flex: 1,
      minWidth: 75,
    },
    {
      field: 'userName',
      headerName: 'Investor Name',
      description: 'The investor\'s name',
      editable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'fileName',
      headerName: 'File Name',
      description: 'The name of the file',
      sortable: true,
      flex: 1,
      minWidth: 200,
      sortComparator: sortAlphanumerically,
    },
    {
      field: 'fundId',
      headerName: 'Fund ID',
      description: 'The fund ID',
      editable: false,
      flex: 1,
      minWidth: 75,
    },
    {
      field: 'errorMessage',
      headerName: 'Upload Error',
      description: 'The error that occurred during upload',
      sortable: true,
      filterable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'uploadDateTime',
      headerName: 'Upload Date',
      description: 'The date and time of upload',
      editable: false,
      flex: 1,
      minWidth: 200,
      sortComparator: sortByDate,
    },
  ];
}
