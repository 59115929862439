import * as React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import Layout from '../Layout';
import LoginCallback from '../LoginCallback';
import LoginRedirect from '../Login';
import UserCache from '../models/User';
import NotFound from '../NotFoundPage';
import UserLogout from '../shared/components/UserLogout';
import AVSnackbar from '../shared/components/SnackBar';
import Dashboard from '../Dashboard';
import ErrorReports from '../ErrorReports';
import SuccessReports from '../SuccessReports';
import Upload from '../Upload';
import DemographicsUpload from '../DemographicsUpload';

function SecureRoutes() {
  const userStorage = new UserCache();
  const user = userStorage.get();
  return (user && (
    <Switch>
      <SecureRoute path="/" exact component={Dashboard} />
      <SecureRoute path="/upload" exact component={Upload} />
      <SecureRoute path="/error-report" exact component={ErrorReports} />
      <SecureRoute path="/success-report" exact component={SuccessReports} />
      <SecureRoute path="/demographics-uploader" exact component={DemographicsUpload} />
      <SecureRoute path="/*" component={NotFound} />
    </Switch>
  ));
}

function Router() {
  const userStorage = new UserCache();
  const user = userStorage.get();
  return (
    <div className="theme-light">
      <div className="wrapper top-navigation">
        <main>
          <div>
            <Layout loading={!user} />
            <AVSnackbar />
            <div className="container__wrap" style={{ padding: '30px' }}>
              <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/login" component={LoginRedirect} />
                <Route path="/logout" exact component={UserLogout} />
                <Route path="/page-not-found" exact component={NotFound} />
                <SecureRoute path="/" component={SecureRoutes} />
              </Switch>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Router;
