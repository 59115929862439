import Cache from './Cache';

export default class User extends Cache {
  constructor() {
    super('user');
  }

  static authMaps = {
    engineering: ['finance admin', 'finance member', 'ooi admin', 'ooi member', 'iteam member', 'treasury'],
    'finance admin': ['finance admin', 'finance member'],
    'ooi admin': ['ooi admin', 'ooi member'],
    'iteam member': ['iteam member'],
    treasury: ['treasury'],
  }

  static createExplicitAuthRoles(userRoles) {
    const roles = new Set();
    const { authMaps } = User;
    for (const authMapKey of Object.keys(authMaps)) {
      if (userRoles.includes(authMapKey)) {
        for (const roleValue of authMaps[authMapKey]) {
          roles.add(roleValue);
        }
      }
    }
    for (const userRole of userRoles) {
      roles.add(userRole);
    }
    return Array.from(roles).sort();
  }

  setUser(user) {
    this.set(user);
    this.selectRole(user.roles[0]);
  }

  selectRole(selectedAuthRole) {
    const user = this.get();
    user.selectedAuthRole = selectedAuthRole;
    this.set(user);
  }

  isEngineeringAdmin() {
    return this.get().selectedAuthRole.includes('engineering');
  }

  isFinance() {
    return this.get().selectedAuthRole.includes('finance');
  }

  isOOI() {
    return this.get().selectedAuthRole.includes('ooi');
  }

  isTreasury() {
    return this.get().selectedAuthRole.includes('treasury');
  }

  isITeam() {
    return this.get().selectedAuthRole.includes('iteam');
  }
}
