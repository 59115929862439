import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import LoadingButton from '@mui/lab/LoadingButton';
import SearchBar from '../shared/components/K1sSearchBar';
import AvDataGrid from '../shared/components/AvDataGrid';
import createK1sTableDefinition from './DashboardTableDefinition';
import { getK1ById } from '../shared/services/apiGateway';

const createTableData = (k1Data) => {
  const tableData = [];
  if (k1Data) {
    for (const investment of k1Data) {
      tableData.push({
        id: investment.id,
        status: investment.status,
        k1FileKey: investment.fileKey,
        fundName: investment.fundName,
        methodOfInvestmentId: investment.methodOfInvestmentId,
        entityName: investment.entityName,
      });
    }
  }
  return tableData;
};

// Shown when a search returns no result
function NoResults() {
  return <h4 className="avtable-no-results">K-1 not found</h4>;
}

// Shown before a search has been run
function NoSearch() {
  return <div />;
}

function LandingPage() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filesDownloadingByKey, setFilesDownloadingByKey] = useState(new Set());
  const k1sTableDef = createK1sTableDefinition(filesDownloadingByKey, setFilesDownloadingByKey);
  const [searchValue, setSearchValue] = useState('');
  const [k1s, setK1s] = useState(null);
  const k1 = createTableData(k1s);

  let abortController;
  const searchForK1 = async () => {
    try {
      if (!searchValue.trim()) return;
      if (abortController) abortController.abort();
      abortController = new AbortController();
      setIsSubmitting(true);
      const response = await getK1ById(searchValue, abortController.signal);
      setK1s(response && response.data ? [response.data] : []);
      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
      setK1s([]);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>K-1 Dashboard</title>
      </Helmet>
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>K-1 Dashboard</h2>
                <Row style={{ marginTop: '20px' }}>
                  <Col>
                    <SearchBar
                      placeholder='Search for a "K-1" by ID'
                      onChange={(e) => setSearchValue(/^\d*$/.test(e.target.value) ? e.target.value : searchValue)}
                      value={searchValue}
                      disabled={isSubmitting}
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          searchForK1();
                        }
                      }}
                    />
                  </Col>
                  <Col xs="auto">
                    <LoadingButton
                      type="submit"
                      sx={{ height: '100%' }}
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting || !searchValue}
                      loading={isSubmitting}
                      onClick={searchForK1}
                    >
                      Search
                    </LoadingButton>
                  </Col>
                </Row>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    hideFooterPagination
                    rows={k1}
                    columns={k1sTableDef}
                    loading={isSubmitting}
                    initialState={{
                      sorting: {
                        sortModel: [
                          { field: 'status', sort: 'asc' },
                        ],
                      },
                    }}
                    components={{
                      NoRowsOverlay: k1s === null ? NoSearch : NoResults,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>

  );
}

export default LandingPage;
