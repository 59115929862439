import { useOktaAuth } from '@okta/okta-react';
import AuthStatusCache from '../../models/AuthStatus';
import TokenCache from '../../models/Token';
import UserCache from '../../models/User';

function logout() {
  const { oktaAuth } = useOktaAuth();
  const dashboardPath = 'https://alumniventuresgroup.okta.com/';

  try {
    const userCache = new UserCache();
    const tokenCache = new TokenCache();
    const authStatusCache = new AuthStatusCache();
    userCache.clear();
    tokenCache.clear();
    authStatusCache.clear();
    oktaAuth.tokenManager.clear();
    window.location.href = dashboardPath;
    console.log('User logged out');
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function UserLogout() {
  logout();
  return '';
}

export default UserLogout;
