import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { getReadonlyK1FileUrlAdmin } from '../shared/services/apiGateway';

const downloadK1File = async (fileKey) => {
  try {
    const res = await getReadonlyK1FileUrlAdmin(fileKey);
    window.open(res.fileUrl, '_blank');
  } catch (err) {
    console.log(err);
  }
};

export default function createK1sTableDefinition(filesDownloadingByKey, setFilesDownloadingByKey) {
  return [
    {
      field: 'id',
      headerName: 'K-1 ID',
      description: 'The K-1 ID',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'The status of the K-1 file',
      sortable: false,
      filterable: false,
      renderCell: (params) => (params.row.k1FileKey ? (
        <LoadingButton
          variant="text"
          color="secondary"
          disabled={filesDownloadingByKey.has(params.row.k1FileKey)}
          loading={filesDownloadingByKey.has(params.row.k1FileKey)}
          onClick={async () => {
            setFilesDownloadingByKey((prev) => new Set([...prev, params.row.k1FileKey]));
            await downloadK1File(params.row.k1FileKey);
            setFilesDownloadingByKey((prev) => {
              const updated = new Set(prev);
              updated.delete(params.row.k1FileKey);
              return updated;
            });
          }}
        >
          Available
        </LoadingButton>
      ) : (
        <span>Pending</span>
      )),
      maxWidth: 125,
      minWidth: 125,
      flex: 1,
    },
    {
      field: 'entityName',
      headerName: 'Entity Name',
      description: 'The entity name',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 250,
    },
    {
      field: 'methodOfInvestmentId',
      headerName: 'Method of Investment ID',
      description: 'The method of investment ID',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 225,
    },
    {
      field: 'fundName',
      headerName: 'Fund Name',
      description: 'The name of the fund',
      sortable: false,
      filterable: false,
      flex: 1,
      minWidth: 300,
    },
  ];
}
