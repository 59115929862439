import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import {
  Container,
  Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';
import Fade from '@mui/material/Fade';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import AvDropzone from '../shared/components/AvDropzone';
import { spacing } from '../shared/themes/appTheme';
import {
  postFileToS3, createPresignedUrlForK1FileImport,
} from '../shared/services/apiGateway';

function Upload() {
  const maxAllowableUploadCount = Number.MAX_SAFE_INTEGER;
  const maxFileSize = 2147483648; // 2GB
  const maxUploadedVisibleCount = 10;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOverwriteChecked, setIsOverwriteChecked] = useState(false);
  const [numUploadedSoFar, setNumUploadedSoFar] = useState(0);
  const [remainingUploadMax, setRemainingUploadMax] = useState(maxAllowableUploadCount);
  const [dzDocumentsToUpload, setDzDocumentsToUpload] = useState([]);
  const [totalNumOfFilesToUpload, setTotalNumOfFilesToUpload] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorText, setErrorText] = useState('');

  const hasInvalidFiles = () => dzDocumentsToUpload.filter((item) => item.errors && item.errors.length > 0).length > 0;

  const uploadFiles = async () => {
    const upload = async (file) => {
      const body = {
        avFileType: file.avFileType,
        contentType: file.type,
        fileName: file.name,
        overwriteExisting: isOverwriteChecked,
      };
      const response = await createPresignedUrlForK1FileImport(body);
      if (!response) {
        setErrorText(`Failed to upload: ${body.fileName}. Please contact support if this error persists.`);
        return null;
      }

      const { url, fileKey } = response;

      try {
        const uploadResponse = await postFileToS3(url, file, body.contentType);
        if (uploadResponse instanceof Error) {
          throw uploadResponse;
        }

        setErrorText('');
        return {
          fileKey,
          avFileType: file.avFileType,
          fileName: body.fileName,
        };
      } catch (error) {
        setErrorText(`An error occurred while uploading: ${body.fileName}. Please contact support if this error persists.`);
        console.error(error);
      }
      return null;
    };
    const newUploadedFiles = [];
    const dzNewUploadedFiles = [];
    let uploadedCount = 0;
    setTotalNumOfFilesToUpload(dzDocumentsToUpload.length);
    setNumUploadedSoFar(uploadedCount);

    if (hasInvalidFiles()) {
      return;
    }

    let quotaRemaining = maxAllowableUploadCount - uploadedFiles.length;
    for (const dzDocumentToUpload of dzDocumentsToUpload) {
      if (quotaRemaining > 0) {
        dzDocumentToUpload.file.avFileType = 'k1-import';
        dzNewUploadedFiles.push(JSON.stringify(dzDocumentToUpload));
        const document = await upload(dzDocumentToUpload.file);
        newUploadedFiles.push(document);
        const combined = [...uploadedFiles, ...newUploadedFiles];
        combined.reverse();
        setUploadedFiles(combined.slice(0, maxUploadedVisibleCount));
        uploadedCount += 1;
        setNumUploadedSoFar(uploadedCount);
        quotaRemaining -= 1;
      }
    }

    setDzDocumentsToUpload(dzDocumentsToUpload.filter((item) => !dzNewUploadedFiles.includes(JSON.stringify(item))));
    setRemainingUploadMax(quotaRemaining);
  };

  return (
    <>
      <Helmet>
        <title> K-1 Uploader </title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h3>K-1 Uploader</h3>
                <p>Please upload all K-1s using our new uploader below.
                </p>
                <p>To upload a file or folder, please make sure to zip your file first. The uploader only accepts .zip type documents.</p>
                <p>To compress a file or folder please Control-click it or tap it using two fingers,
                  then choose Compress from the shortcut menu.
                </p>
                <p>Once you compress a file or folder you will see your original file with the .zip extension.
                  Then simply drag and drop the zipped file into the uploader, or click the uploader to locate the zipped file.
                  Once files have been added, press Upload K-1s to initiate the upload process.
                </p>
              </Col>
            </Row>
            <Row className="w-100">
              <Col style={{
                marginTop: spacing(2),
              }}
              >
                {(remainingUploadMax > 0) && (
                  <AvDropzone
                    header={false}
                    maxFiles={remainingUploadMax}
                    maxFileSize={maxFileSize}
                    onFilesChange={setDzDocumentsToUpload}
                    style={{ width: '100%' }}
                    value={dzDocumentsToUpload}
                    accept=".zip"
                    disabled={isSubmitting}
                    label={(
                      <div className="av-dropzone-label">
                        <h5>Please select one or more K-1 files to upload here.</h5>
                      </div>
                    )}
                  />
                )}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  mt={2}
                >
                  <FormGroup row>
                    <FormControlLabel
                      style={{}}
                      control={(
                        <Checkbox
                          inputProps={{ 'aria-label': 'controlled' }}
                          checked={isOverwriteChecked}
                          onClick={() => { setIsOverwriteChecked(!isOverwriteChecked); }}
                          disabled={isSubmitting}
                        />
                      )}
                      label={<p>Overwrite Existing K-1 Files</p>}
                    />
                  </FormGroup>
                  <div>
                    {!!totalNumOfFilesToUpload && (
                    <span style={{ textAlign: 'right', marginRight: '16px', display: 'inline-block' }}>
                      Uploaded {numUploadedSoFar} of {totalNumOfFilesToUpload} file{totalNumOfFilesToUpload > 1 ? 's' : ''}&nbsp;
                      ({ ((numUploadedSoFar / totalNumOfFilesToUpload) * 100).toFixed(1) }%)
                    </span>
                    )}
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting || dzDocumentsToUpload.length <= 0 || hasInvalidFiles()}
                      loading={isSubmitting}
                      onClick={async () => {
                        setIsSubmitting(true);
                        await uploadFiles();
                        setIsSubmitting(false);
                      }}
                    >
                      Upload K-1s
                    </LoadingButton>
                  </div>
                </Stack>
                {(isSubmitting) && (
                  <Fade in timeout={1200}>
                    <div style={{ margin: '30px 0 30px 0' }}>
                      <h4>
                        Please wait while your upload processes. Do not navigate away from or refresh the page.
                      </h4>
                    </div>
                  </Fade>
                )}
                <h4 className="error red">{errorText}</h4>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Upload;
