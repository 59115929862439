export default function downloadFile(fileData, fileName, fileType = 'application/pdf') {
  const blobObj = new Blob([fileData], { type: fileType });
  const downloadUrl = window.URL.createObjectURL(blobObj);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = downloadUrl;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(downloadUrl);
}
