/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions,
jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-has-content */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from 'react-router-dom';
import TopbarNav from './topbarNav/TopbarNav';
import TopbarProfile from './TopbarProfile';
import SidebarSwipeable from './sidebarMobile/SidebarSwipeable';
import UserStorage from '../../models/User';
import HorizRecLoader from '../../shared/components/Loader/HorizRecLoader';

function TopbarInvestor(props) {
  // eslint-disable-next-line react/prop-types
  const { loading } = props;
  const userStorage = new UserStorage();
  const user = userStorage.get();
  const matches = useMediaQuery('(max-width:991px)');
  const history = useHistory();

  return (
    <div
      className="topbar topbar--navigation"
      style={{ position: 'relative', zIndex: 1400 }}
    >
      <Helmet>
        <title>Alumni Ventures</title>
        <link rel="shortcut icon" href={`${process.env.AVG_API_PATH}/favicon.ico`} sizes="16x16" />
      </Helmet>
      <div className="topbar__wrapper">
        <div className="topbar__left">
          {matches && user && (
            <SidebarSwipeable user={user} />
          )}
          {loading ? HorizRecLoader(1, 150, 30) : (
            <a
              style={{
                marginLeft: '15px', marginTop: '10px', marginRight: '15px', display: 'block', cursor: 'pointer',
              }}
              className="topbar__logo"
              onClick={() => history.push('/')}
            />
          )}
          {loading ? HorizRecLoader(1, 200, 30) : <TopbarNav />}
        </div>
        <div className="topbar__right">
          {loading ? HorizRecLoader(1, 120, 30) : <TopbarProfile user={user} />}
        </div>
      </div>
    </div>
  );
}

export default TopbarInvestor;
