import {
  getRequest, postRequest, putS3Request, deleteRequest,
} from './request';
import { checkTokenValidity } from './tokenServices';

const getHeaders = async () => {
  const accessToken = await checkTokenValidity();
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };
  return headers;
};

export const createDDReportForDeal = async (body) => {
  const headers = await getHeaders();
  const res = await postRequest(
    `${process.env.K1_ADMIN_API_PATH}/due-diligence`,
    body,
    headers,
  );
  return res;
};

export const createPresignedUrlForK1FileImport = async (body) => {
  const headers = await getHeaders();

  const res = await postRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1-file-import`,
    body,
    headers,
    null,
    false,
  );
  return res;
};

export const postFileToS3 = async (url, data, fileType, abortSignal = null) => {
  try {
    await checkTokenValidity();
    const headers = { 'Content-Type': fileType, Accept: 'application/json' };
    const res = await putS3Request(
      url,
      data,
      headers,
      abortSignal,
      false,
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const deleteDocuments = async (body, abortSignal = null) => {
  const headers = await getHeaders();
  const {
    deal,
  } = body;

  const res = await deleteRequest(
    `${process.env.K1_ADMIN_API_PATH}/resource-url?deal=${deal}`,
    body,
    headers,
    abortSignal,
  );
  return res;
};

export const loginAVAdminUser = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const res = await postRequest(
    `${process.env.AUTH_SERVICE_PATH}/login`,
    body,
    headers,
    null,
    false,
  );
  return res;
};

export const refreshAVAdminUserToken = async (body) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const res = await postRequest(
    `${process.env.AUTH_SERVICE_PATH}/refresh`,
    body,
    headers,
  );
  return res;
};

export const getDueDiligenceReport = async (id, abortSignal = null, onError = (error) => error && error.statusCode === 404) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/dd-report/${id}`,
    headers,
    abortSignal,
    onError,
  );
  return res;
};

export const getErrorReports = async (abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1-rejections`,
    headers,
    abortSignal,
    () => true,
  );
  return res;
};

export const getSuccessReports = async (abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1-success`,
    headers,
    abortSignal,
    () => true,
  );
  return res;
};

export const getK1ById = async (
  id,
  abortSignal = null,
  onError = (error) => error && (error.statusCode === 404 || error.name === 'AbortError'),
) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1/${id}`,
    headers,
    abortSignal,
    onError,
  );
  return res;
};

export const getReadonlyK1FileUrlAdmin = async (fileKey, abortSignal = null) => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1-file-admin?fileKey=${fileKey}`,
    headers,
    abortSignal,
  );
  return res;
};

export const getErrorReportsCsv = async () => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1-rejections-export`,
    headers,
  );
  return res;
};

export const getSuccessReportsCsv = async () => {
  const headers = await getHeaders();
  const res = await getRequest(
    `${process.env.K1_ADMIN_API_PATH}/k1-success-export`,
    headers,
  );
  return res;
};
