import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import Fade from '@mui/material/Fade';
import {
  Card, CardBody, Row, Col,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import AvDropzone from '../shared/components/AvDropzone';
import { spacing } from '../shared/themes/appTheme';
import {
  postFileToS3, createPresignedUrlForK1FileImport,
} from '../shared/services/apiGateway';

function Upload() {
  const maxAllowableUploadCount = 1;
  const maxFileSize = 2147483648; // 2GB
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [numUploadedSoFar, setNumUploadedSoFar] = useState(0);
  const [dzDocumentsToUpload, setDzDocumentsToUpload] = useState([]);
  const [errorText, setErrorText] = useState('');

  const hasInvalidFiles = () => dzDocumentsToUpload.filter((item) => item.errors && item.errors.length > 0).length > 0;

  const uploadFiles = async () => {
    const upload = async (file) => {
      const body = {
        avFileType: file.avFileType,
        contentType: file.type,
        fileName: file.name,
        fileDatatype: 'demographics',
      };
      const response = await createPresignedUrlForK1FileImport(body);
      if (!response) {
        setErrorText(`Failed to upload: ${body.fileName}. Please contact support if this error persists.`);
        return null;
      }

      const { url, fileKey } = response;

      try {
        const uploadResponse = await postFileToS3(url, file, body.contentType);
        if (uploadResponse instanceof Error) {
          throw uploadResponse;
        }

        setErrorText('');
        return {
          fileKey,
          avFileType: file.avFileType,
          fileName: body.fileName,
        };
      } catch (error) {
        setErrorText(`An error occurred while uploading: ${body.fileName}. Please contact support if this error persists.`);
        console.error(error);
      }
      return null;
    };
    const newUploadedFiles = [];
    const dzNewUploadedFiles = [];

    if (hasInvalidFiles()) {
      return;
    }
    setNumUploadedSoFar(0);
    dzNewUploadedFiles.push(JSON.stringify(dzDocumentsToUpload[0]));
    const document = await upload(dzDocumentsToUpload[0].file);
    if (document) {
      newUploadedFiles.push(document);
    }
    setNumUploadedSoFar(newUploadedFiles.length);
    setDzDocumentsToUpload(dzDocumentsToUpload.filter((item) => !dzNewUploadedFiles.includes(JSON.stringify(item))));
  };

  return (
    <>
      <Helmet>
        <title> Demographics Uploader </title>
      </Helmet>
      <Container className="med-width-form">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h3>Demographics Uploader</h3>
                <p>Please upload demographics data using our new uploader below.
                </p>
                <p>The uploader only accepts single .csv type documents and uploades file for further processing.</p>
                <p>Simply drag and drop the CSV file into the uploader, or click the uploader to locate the CSV file.
                  Once file have been added, press Upload demographics File to initiate the upload process.
                </p>
              </Col>
            </Row>
            <Row className="w-100">
              <Col style={{
                marginTop: spacing(2),
              }}
              >
                <AvDropzone
                  header={false}
                  maxFiles={maxAllowableUploadCount}
                  maxFileSize={maxFileSize}
                  onFilesChange={setDzDocumentsToUpload}
                  style={{ width: '100%' }}
                  value={dzDocumentsToUpload}
                  accept=".csv"
                  disabled={isSubmitting}
                  label={(
                    <div className="av-dropzone-label">
                      <h5>Please select one demographics files to upload here.</h5>
                    </div>
                )}
                />
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  mt={2}
                >
                  {!!numUploadedSoFar && (
                    <span style={{ textAlign: 'right', marginRight: '16px', display: 'inline-block' }}>
                      Uploaded {numUploadedSoFar} of {maxAllowableUploadCount} file{maxAllowableUploadCount > 1 ? 's' : ''}&nbsp;
                      ({ ((numUploadedSoFar / maxAllowableUploadCount) * 100).toFixed(1) }%)
                    </span>
                  )}
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting || dzDocumentsToUpload.length <= 0 || hasInvalidFiles()}
                    loading={isSubmitting}
                    onClick={async () => {
                      setIsSubmitting(true);
                      await uploadFiles();
                      setIsSubmitting(false);
                    }}
                  >
                    Upload Demographic File
                  </LoadingButton>
                </Stack>
                {(isSubmitting) && (
                  <Fade in timeout={1200}>
                    <div style={{ margin: '30px 0 30px 0' }}>
                      <h4>
                        Please wait while your upload processes. Do not navigate away from or refresh the page.
                      </h4>
                    </div>
                  </Fade>
                )}
                <h4 className="error red">{errorText}</h4>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default Upload;
