import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container, Row, Card, CardBody, Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import SearchBar from '../shared/components/K1sSearchBar';
import AvDataGrid from '../shared/components/AvDataGrid';
import useErrorReports from '../shared/hooks/useErrorReports';
import createErrorReportsTableDefinition from './ErrorReportsTableDefinition';
import useFilteredBySearch from '../shared/hooks/useFilteredBySearch';
import formatDateTimeString from '../shared/utils/formatDateTimeString';
import downloadFile from '../shared/utils/downloadFile';
import {
  getErrorReportsCsv,
} from '../shared/services/apiGateway';

// create table data
const createTableData = (data) => {
  const tableData = [];
  if (data) {
    for (const k1 of data) {
      tableData.push({
        ...k1,
        id: v4(),
        userName: k1.firstName ? `${k1.firstName} ${k1.lastName}` : '',
        uploadDateTime: formatDateTimeString(k1.uploadDateTime),
      });
    }
  }
  return tableData;
};
function ErrorReports() {
  const [loadingErrorReports, setErrorReportsLoading] = useState(true);
  const errorReports = useErrorReports(setErrorReportsLoading);
  const errorReportsTableDef = createErrorReportsTableDefinition(useHistory());
  const [searchValue, setSearchValue] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const filteredErrorReports = createTableData(useFilteredBySearch(errorReports, searchValue));
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <Helmet>
        <title>Error Report</title>
      </Helmet>
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <h2>Error Report</h2>
                <div style={{ marginTop: '20px' }}>
                  <SearchBar
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    disabled={loadingErrorReports}
                    fullWidth
                  />
                </div>
                <div style={{ marginTop: '20px' }}>
                  <AvDataGrid
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    rows={filteredErrorReports}
                    columns={errorReportsTableDef}
                    loading={loadingErrorReports}
                    initialState={{
                      sorting: {
                        sortModel: [
                          { field: 'uploadDateTime', sort: 'desc' },
                        ],
                      },
                    }}
                  />
                </div>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  mt={2}
                >
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting || loadingErrorReports}
                    loading={isSubmitting || loadingErrorReports}
                    onClick={async () => {
                      setIsSubmitting(true);
                      const response = await getErrorReportsCsv();
                      const csvData = response.data;
                      downloadFile(csvData, `k1-error-export-${new Date().toISOString()}.csv`);
                      setIsSubmitting(false);
                    }}
                  >
                    Download As CSV
                  </LoadingButton>
                </Stack>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>

  );
}

export default ErrorReports;
