/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from 'mdi-react/MenuIcon';
import User from '../../../models/User';

const styles = {
  list: {
    width: 250,
    marginTop: '60px',
  },
  text: {
    color: '#104866',
    fontSize: '14px',
    fontFamily: '"Inter", "Roboto", sans-serif',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  nested: {
    paddingLeft: 24,
  },
};

const useStyles = makeStyles(styles);

function SwipeableTemporaryDrawer() {
  const user = new User();
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = React.useState({
    open: false,
  });
  const navigate = (link) => {
    if (link.indexOf('http') !== -1) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };

  const toggleDrawer = (openNewVal) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open: openNewVal });
  };

  const makeMenuItem = (title, icon, path) => ({
    title,
    icon,
    path,
  });

  const menuList = [
  ];

  if (!user.isITeam()) {
    menuList.push(makeMenuItem('Dashboard', 'list', '/'));
    menuList.push(makeMenuItem('K-1 Uploader', 'list', '/upload'));
    menuList.push(makeMenuItem('Error Report', 'list', '/error-report'));
    menuList.push(makeMenuItem('Success Report', 'list', '/success-report'));
    menuList.push(makeMenuItem('Demographics Uploader', 'list', '/demographics-uploader'));
  }
  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuList.map((obj) => (
          <React.Fragment key={obj.title}>
            <ListItem
              button
              key={obj.title}
              onClick={(e) => {
                e.preventDefault();
                navigate(obj.path);
              }}
              className={classes.text}
            >
              {obj.icon && (<span className={`topbar__link-icon lnr lnr-${obj.icon}`} />)}
              <ListItemText
                disableTypography
                primary={obj.title}
                classes={{ primary: classes.text }}
                className={classes.text}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <React.Fragment key="left">
      <IconButton onClick={toggleDrawer(!state.open)} style={{ paddingLeft: '10px' }}>
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={state.open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.root,
        }}
      >
        {list()}
      </SwipeableDrawer>
    </React.Fragment>
  );
}

export default SwipeableTemporaryDrawer;
