import React from 'react';
import { Link } from 'react-router-dom';
import './topbarNav.css';
import User from '../../../models/User';

function TopbarNav() {
  const user = new User();
  return (
    <nav className="topbar__nav" style={{ textDecorationLine: 'none' }}>
      <Link className="topbar__nav-link" to="/">
        Dashboard
      </Link>
      <Link className="topbar__nav-link" to="/upload/">
        K-1 Uploader
      </Link>
      <Link className="topbar__nav-link" to="/error-report/">
        Error Report
      </Link>
      <Link className="topbar__nav-link" to="/success-report/">
        Success Report
      </Link>
      <Link className="topbar__nav-link" to="/demographics-uploader/">
        Demographics Uploader
      </Link>
    </nav>
  );
}

export default TopbarNav;
