/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import MD5 from 'md5.js';
import { Collapse } from 'reactstrap';
import { logoutUser } from '@alumni-ventures-group/av-okta-auth';
import ava from 'url:./../../images/ava.png';
import TopbarMenuLink from './TopbarMenuLink';

function TopbarProfile({ user }) {
  const topbarMenu = useRef(null);
  const [collapse, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [avatar, setAvatar] = useState(ava);
  const { authState } = useOktaAuth();

  const setUser = (updatedUser) => {
    const gravatarHash = updatedUser.email
      ? new MD5().update(updatedUser.email.trim().toLowerCase()).digest('hex') : null;
    const updatedAvatar = updatedUser.avatar ? updatedUser.avatar
      : `https://www.gravatar.com/avatar/${gravatarHash}?s=100&d=${encodeURI(
        'https://la-company-logos.s3.amazonaws.com/avg-avatar.jpg',
      )}`;
    setUsername((
      `${updatedUser.first_name ? updatedUser.first_name
        : updatedUser.firstName} ${updatedUser.last_name ? updatedUser.last_name : updatedUser.lastName}`
    ).toUpperCase());
    setAvatar(updatedAvatar);
  };

  const collapseTopbarMenu = (e) => {
    if (topbarMenu.current && collapse && !topbarMenu.current.contains(e.target)) {
      setOpen(false);
    }
  };

  // Handle a click outside of the profile menu to close it when open
  useEffect(() => {
    if (collapse) {
      document.addEventListener('mousedown', collapseTopbarMenu);
    }
    return () => {
      document.removeEventListener('mousedown', collapseTopbarMenu);
    }
  }, [collapse]);

  useEffect(() => {
    if (user.email) {
      setUser(user);
    }
  }, [user]);

  if (!authState) {
    return null;
  }

  return (
    <div ref={topbarMenu} className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={() => { setOpen(!collapse); }}>
        <img className="topbar__avatar-img" src={avatar} alt="avatar" />
        <p className="topbar__avatar-name">{username}</p>
        {/* <DownIcon className="topbar__icon" /> */}
      </button>
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Logout"
            icon="Logout"
            onClick={() => {
              logoutUser();
            }}
          />
        </div>
      </Collapse>
    </div>
  );
}

TopbarProfile.propTypes = {
  user: PropTypes.any.isRequired,
};

export default TopbarProfile;
