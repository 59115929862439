import fetch from 'cross-fetch';
import { notificationStore } from '../store/NotificationStore';

const setNotification = (status, message = null) => {
  notificationStore.setNotification({ ...notificationStore.notification, status, message });
};

const showErrorMessage = (json) => {
  console.error('request error:', json);
  if (json && json.message) {
    return setNotification('error', json.message);
  }
  return setNotification('error');
};

export const getRequest = (url, headers, abortSignal = null, customErrorHandler = () => false) => fetch(url, {
  method: 'GET',
  headers,
  signal: abortSignal,
}).then((response) => response.json().then((json) => {
  if (!response.ok) {
    return Promise.reject(json);
  }
  return json;
})).catch((e) => {
  if (!customErrorHandler(e)) {
    showErrorMessage(e);
    return Promise.reject(e);
  }
});

export const postRequest = (url, body, headers, abortSignal = null, showSuccessNotification = true) => fetch(url, {
  method: 'POST',
  body: JSON.stringify(body),
  headers,
  signal: abortSignal,
}).then(async (res) => res.json().then((json) => {
  if (!res.ok) {
    return Promise.reject(json);
  }

  if (showSuccessNotification) {
    setNotification('success');
  }
  return json;
})).catch((e) => {
  showErrorMessage(e);
  return Promise.reject(e);
});

export const deleteRequest = (url, body, headers, abortSignal = null) => fetch(url, {
  method: 'DELETE',
  body: JSON.stringify(body),
  headers,
  signal: abortSignal,
}).then(async (res) => res.json().then((json) => {
  if (!res.ok) {
    return Promise.reject(json);
  }
  setNotification('success');
  return json;
})).catch((e) => {
  showErrorMessage(e);
  return Promise.reject(e);
});

export const putS3Request = (url, body, headers, abortSignal = null, showSuccessNotification = true) => fetch(url, {
  method: 'PUT',
  body,
  headers,
  signal: abortSignal,
}).then(async (res) => res.text().then((json) => {
  if (!res.ok) {
    return Promise.reject(json);
  }
  if (showSuccessNotification) {
    setNotification('success');
  }
  return json;
})).catch((e) => {
  showErrorMessage(e);
  return Promise.reject(e);
});

export const postRequestFormData = (url, body, headers, abortSignal = null) => fetch(url, {
  method: 'POST',
  body,
  headers,
  signal: abortSignal,
}).then(async (res) => res.json().then((json) => {
  if (!res.ok) {
    return Promise.reject(json);
  }
  setNotification('success');
  return json;
})).catch((e) => {
  showErrorMessage(e);
  return Promise.reject(e);
});
