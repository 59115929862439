/* eslint-disable no-param-reassign, react/jsx-props-no-spreading, no-nested-ternary */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import { DataGridPro, gridClasses, LicenseInfo } from '@mui/x-data-grid-pro';
import './avDataGrid.css';
import { Box, Tooltip, Skeleton } from '@mui/material';
import { v4 } from 'uuid';
import sortAlphanumerically from '../../utils/sortAlphanumerically';

LicenseInfo.setLicenseKey(process.env.MUI_X_LICENSE);

const AvDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[50],
    '&:hover, &.Mui-hovered': {
      backgroundColor: theme.palette.grey[100],
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  fontFamily: "'Inter', 'Roboto', sans-serif",
  '& p': {
    marginTop: '0px',
  },
}));

function FullViewTooltip(params) {
  const { value } = params;
  return (
    <Tooltip title={<span className="avtable-cell-tooltip">{value}</span>} enterNextDelay={700} enterDelay={700} leaveDelay={0}>
      <span className="avtable-cell-trucate">{value}</span>
    </Tooltip>
  );
}

function LoadingSkeleton(rowCount) {
  return (
    <Box sx={{
      height: 'max-content',
    }}
    >
      {[...Array(rowCount + 2)].map(() => (
        <Skeleton variant="rectangular" sx={{ my: 2, mx: 1 }} height={35} key={v4()} />
      ))}
    </Box>
  );
}

function NoResults() {
  return (<h4 className="avtable-no-results">No Results</h4>);
}

export default function Grid(params) {
  const {
    columns, pageSize = 10, loading, setPageSize, rows, components,
  } = params;
  const calcLoading = loading !== false;
  const onPageSizeChange = setPageSize ? (newPageSize) => setPageSize(newPageSize) : () => false;
  const rowsPerPageArray = setPageSize ? [5, 10, 20, 50] : [];

  if (columns) {
    columns.forEach((c) => {
      if (!c.sortComparator) {
        c.sortComparator = sortAlphanumerically;
      }
      if (!c.renderCell) {
        c.renderCell = FullViewTooltip;
      }
    });
  }

  if (rows && rows.length > 50 && rows.length < 200 && rowsPerPageArray) {
    rowsPerPageArray.push(rows.length);
  }

  return (
    calcLoading ? LoadingSkeleton(pageSize) : (
      <AvDataGrid
        pagination
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={rowsPerPageArray}
        disableSelectionOnClick
        autoHeight
        getRowClassName={(row) => ((row.indexRelativeToCurrentPage + 1) % 2 === 0 ? 'even' : 'odd')}
        components={{
          NoRowsOverlay: NoResults,
          ...components,
        }}
        {...params}
      />
    )
  );
}
