import jwt from 'jsonwebtoken';
import TokenCache from '../../models/Token';
import UserCache from '../../models/User';
import logoutUser from './logoutUser';
import { refreshAVAdminUserToken } from './apiGateway';

export const refreshAuthToken = async () => {
  // verify refresh token. if valid, then call /refresh and set new access and refresh token
  try {
    // call /refresh with email and provider from apigateway
    const tokenCache = new TokenCache();
    const userCache = new UserCache();

    const refreshToken = tokenCache.getProp('refresh_token');
    const authProviderData = { avRefreshToken: refreshToken };
    const userEmail = userCache.getProp('email');
    const { email, provider } = jwt.decode(refreshToken);

    // compare access and refresh token's emails and, potentially, validity
    if (email === userEmail) {
      const payload = { email, provider, authProviderData };
      const res = await refreshAVAdminUserToken(payload);
      const newAccessToken = res.access_token;
      tokenCache.setProp('access_token', newAccessToken);
      return newAccessToken;
    }
    return await logoutUser();
  } catch (error) {
    // if not valid, clear local storage and log out user via okta signout
    return logoutUser();
  }
};

export const checkTokenValidity = async () => {
  try {
    const tokenCache = new TokenCache();
    const accessToken = tokenCache.get().access_token;
    const refreshToken = tokenCache.get().refresh_token;

    const now = Math.floor(Date.now() / 1000);
    const decodedAccessToken = jwt.decode(accessToken);

    if (decodedAccessToken.refreshToken === refreshToken) {
      const decodedRefreshToken = jwt.decode(refreshToken);
      if (decodedAccessToken.exp > now) {
        return accessToken;
      }
      if (decodedRefreshToken.exp > now) {
        return refreshAuthToken();
      }
    }
    return await logoutUser();
  } catch (error) {
    return logoutUser();
  }
};
